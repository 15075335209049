import API from "@utils/request";

const TASK_APPLY = '/index/';

/**
 * 任务台账
 * @param {object} params 
 * @returns 
 */
export async function taskApplyStandingBook(params) {
    try {
        return await API.post(TASK_APPLY + 'taskApplyStandingBook', params)
    } catch (error) {
        return error;
    }
}

/**
 * 任务台账-导出
 * @param {object} params 
 * @returns 
 */
 export async function taskApplyStandingBookExport(params) {
    try {
        return await API.post(TASK_APPLY + 'taskApplyStandingBookExport', params, {
            responseType: "blob",
        });
    } catch (error) {
        return error;
    }
}

/**
 * 详情
 * @param {int|string} id 
 * @returns 
 */
export async function taskApplyDetail(id) {
    try {
        return await API.post(TASK_APPLY + "taskApplyDetail", {
            id: id
        });
    } catch (error) {
        return error;
    }
}
